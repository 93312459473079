export const boton_desarrollo_app={
    data() {
        return { 
          showconsole:false
        }
      },  
      methods: {
        abrirconsola:function(){
          var id="console"
          if (this.showconsole){
            document.getElementById(id).style.display = 'none';
            this.showconsole=!this.showconsole
          } else {
            document.getElementById(id).style.display = 'block';
            this.showconsole=!this.showconsole
          }
        }
      }
}